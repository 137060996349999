import React from 'react'

export const ForgotPasswordMessage = () => {
  return (
    <p className="text-xs leading-loose mb-24 text-left">
      Your data is <strong>end-to-end encrypted</strong>. Recovering your
      password is only possible if you've checked "Keep me logged in", provided
      an email, and use the temporary password on a browser and device where
      you've logged in before
      <strong>
        TL;DR: store you password in a safe place, we may not be able to recover
        it for you.
      </strong>
    </p>
  )
}
