import React from 'react'
import { navigate, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { useUserbaseUserState } from '../services/userbase/userProvider'
import { Form } from '../components/form'
import { Input } from '../components/input'
import { Button } from '../components/button'
import { useUserbaseUser } from '../services/userbase'
import { LoginForm } from '../components/login-form'

const ForgotPasswordPage = () => {
  const { user, ready } = useUserbaseUserState()
  const { forgotPassword } = useUserbaseUser()
  const [username, setUsername] = React.useState('')
  const [error, setError] = React.useState('')
  const [success, setSuccess] = React.useState(false)

  if (!ready) {
    // @TODO should be seeing splash screen
    return null
  }

  if (user) {
    navigate('/', { replace: true })
    return null
  }

  if (success) {
    return (
      <Layout>
        <SEO title="Forgot Password" />
        <LoginForm brief prefillUsername={username}>
          <h1 className="hd-xl text-blue-bright mb-24">Check your Email</h1>
          <p className="mb-24">
            Log in with the temporary password you recieved.
          </p>
        </LoginForm>
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO title="Forgot Password" />

      <Form
        className="mx-auto"
        onSubmit={async (e) => {
          e.preventDefault()
          const response = await forgotPassword(username)

          if (response.status !== 200) {
            const error = response
            setError(error.message || 'An error occurred')
          } else if (response.status === 200) {
            // @TODO set flag to prompt password change
            setSuccess(true)
          }
        }}
      >
        <h1 className="hd-xl text-blue-bright mb-24">Forgot Password</h1>

        <Input
          className="mb-24"
          id="username"
          value={username}
          setValue={setUsername}
          error={error}
          onErrorClear={() => setError('')}
        >
          Username
        </Input>

        <Button
          className="block min-w-200 mb-32"
          disabled={!username}
          type="submit"
        >
          Submit
        </Button>
        <Link
          className="block util-label text-blue-organic no-underline pt-4 mb-24"
          to="/login"
        >
          Remembered you password? Log in!
        </Link>
        <Link
          className="block util-label text-blue-organic no-underline mb-24"
          to="/register"
        >
          Don't have an account? Register!
        </Link>
      </Form>
    </Layout>
  )
}

export default ForgotPasswordPage
