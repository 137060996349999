import React from 'react'
import cx from 'classnames'
import { bool, func, node, string } from 'prop-types'

export const Checkbox = ({ id, value, setValue, className, children }) => {
  return (
    <div className={cx(className, 'checkbox ml-24 flex items-center')}>
      <input
        className="sr-only"
        id={id}
        type="checkbox"
        checked={value}
        onChange={() => setValue(!value)}
      />
      <label className="util-sm uppercase text-blue-organic" htmlFor={id}>
        {children}
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  children: node,
  className: string,
  id: string.isRequired,
  setValue: func.isRequired,
  value: bool.isRequired,
}
