export const GENERIC_ERRORS = [
  // sign-in
  'ParamsMustBeObject',
  'RememberMeValueNotValid',
  'SessionLengthMustBeNumber',
  'SessionLengthTooShort',
  'SessionLengthTooLong',
  'KeyNotFound',
  'AppIdNotSet',
  'AppIdNotValid',
  'ServiceUnavailable',
  // sign-up
  'TrialExceededLimit',
]

export const USERNAME_ERRORS = [
  // sign-in
  'UsernameMissing',
  'UsernameOrPasswordMismatch',
  'UsernameCannotBeBlank',
  'UsernameTooLong',
  'UsernameMustBeString',
  'UserAlreadySignedIn',
  // sign-up
  'UsernameAlreadyExists',
]

export const PASSWORD_ERRORS = [
  'PasswordMissing',
  'PasswordCannotBeBlank',
  'PasswordTooShort',
  'PasswordTooLong',
  'PasswordMustBeString',
  'PasswordAttemptLimitExceeded',
]

export const EMAIL_ERRORS = ['EmailNotValid']

export const PROFILE_ERRORS = [
  'ProfileMustBeObject',
  'ProfileCannotBeEmpty',
  'ProfileHasTooManyKeys',
  'ProfileKeyMustBeString',
  'ProfileKeyTooLong',
  'ProfileValueMustBeString',
  'ProfileValueCannotBeBlank',
  'ProfileValueTooLong',
]
