import React from 'react'
import { node, bool, string } from 'prop-types'
import { Link } from 'gatsby'

import { Form } from '../form'
import { Button } from '../button'
import { Input } from '../input'
import { Checkbox } from '../checkbox'
import { ForgotPasswordMessage } from '../forgot-password-message'

import { useUserbaseUser } from '../../services/userbase'
import { USERNAME_ERRORS, PASSWORD_ERRORS } from '../../domains/userbase'

export const LoginForm = ({ children, brief, prefillUsername = '' }) => {
  const [username, setUsername] = React.useState(prefillUsername)
  const [password, setPassword] = React.useState('')
  const [errors, setErrors] = React.useState({
    username: '',
    password: '',
  })
  const [rememberMe, setRememberMe] = React.useState(true)
  const { signIn } = useUserbaseUser()

  const handleSubmit = async (e) => {
    // @TODO loading spinner...

    e.preventDefault()
    const response = await signIn(username, password, rememberMe)

    if (response.status !== 200) {
      if (USERNAME_ERRORS.includes(response.name)) {
        setErrors({ ...errors, username: response.message })
      } else if (PASSWORD_ERRORS.includes(response.name)) {
        setErrors({ ...errors, password: response.message })
      } else {
        // @TODO dispatch global banner
      }
    }
  }

  return (
    <Form className="mx-auto" onSubmit={handleSubmit}>
      {children}

      <Input
        className="mb-24"
        id="username"
        value={username}
        setValue={setUsername}
        error={errors.username}
        onErrorClear={() => setErrors({ ...errors, username: '' })}
      >
        Username
      </Input>

      <Input
        className="mb-24"
        id="password"
        value={password}
        setValue={setPassword}
        type="password"
        error={errors.password}
        onErrorClear={() => setErrors({ ...errors, password: '' })}
      >
        Password
      </Input>

      {!brief && (
        <Checkbox
          className="mb-24"
          id="remember-me"
          value={rememberMe}
          setValue={setRememberMe}
        >
          Keep me logged in
        </Checkbox>
      )}

      {!brief && <ForgotPasswordMessage />}

      <Button
        className="block min-w-200 mb-32"
        disabled={!username || !password}
        type="submit"
      >
        Submit
      </Button>

      {!brief && (
        <>
          <Link
            className="block util-label text-blue-organic no-underline pt-4 mb-24"
            to="/forgot-password"
          >
            Forgot your password?
          </Link>
          <Link
            className="block util-label text-blue-organic no-underline mb-24"
            to="/register"
          >
            Don't have an account?
          </Link>
        </>
      )}
    </Form>
  )
}

LoginForm.propTypes = {
  children: node,
  brief: bool,
  prefillUsername: string,
}
